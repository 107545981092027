const Tips = [
  'Response Body on the left is the response you wish to obtain.',
  'Mocktail is a free, containerized open-source mock server.',
  'Please open tickets if you encounter any bugs on github.com/Huseyinnurbaki/mocktail',
  'You can email me if you have any questions, feedbacks or suggetions. huseyin.nurbaki@gmail.com ',
  'Original docker image is hhaluk/mocktail',
  'Apis are listed under Catalog tab.',
  'You can import exported apis.',
  'Image size is only 11MB.',
  'Contributions & suggestions are welcomed!'
];

export default Tips;
